import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPriceGroups(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/price-groups', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPriceGroupDiscountRates(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/price-groups-discount-rates', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addPriceGroup(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/price-groups', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePriceGroup(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/price-groups/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePriceGroup(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/price-groups/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
