<template>

  <div>

    <b-row>
      <b-col md="4">
        <b-card
          class="mb-0"
        >

          <b-row>
            <b-col md="12">
              <div class="d-flex align-items-center justify-content-end">

                <b-button
                  size="sm"
                  variant="primary"
                  @click="resetPriceGroupData()"
                >
                  {{ $t('Yeni Fiyat Grubu Ekle') }}
                </b-button>
              </div>
              <hr>
              <b-list-group>
                <b-list-group-item
                  v-for="(item,index) in priceGroupsData"
                  :key="index"
                  class="d-flex justify-content-between align-items-center"
                >{{ item.name }}
                  <b-dropdown
                    variant="link"
                    no-caret
                    size="sm"
                    :right="$store.state.appConfig.isRTL"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="12"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item @click="selectDataForEdit(item)">
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">{{ $t('Düzenle') }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="!item.is_admin"
                      variant="danger"
                      @click="deleteData(index,item.id)"
                    >
                      <feather-icon icon="Trash2Icon" />
                      <span class="align-middle ml-50">{{ $t('Sil') }}</span>
                    </b-dropdown-item>
                  </b-dropdown></b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>

        </b-card>
      </b-col>
      <b-col md="8">
        <b-card
          v-if="selectedPriceGroup"
          class="mb-0"
        >
          <b-card-title v-if="selectedPriceGroup.id==0">
            Yeni Fiyat Grubu Oluştur
          </b-card-title>
          <b-card-title v-if="selectedPriceGroup.id!=0 && !selectedPriceGroup.is_admin">
            {{ selectedPriceGroup.name }} İsimli Fiyat Grubunu Düzenle
          </b-card-title>

          <b-row>
            <b-col md="12">

              <validation-observer
                #default="{ handleSubmit }"
                ref="refFormObserver"
              >
                <!-- Form -->
                <b-form
                  class="p-2"
                  @submit.prevent="handleSubmit(saveData)"
                  @reset.prevent="resetForm"
                >

                  <!-- Firma ad -->
                  <validation-provider
                    #default="validationContext"
                    :name="$t('Fiyat Grubu Adı')"
                    rules="required|min:2"
                  >
                    <b-form-group
                      :label="$t('Fiyat Grubu Adı')"
                      label-for="name"
                    >
                      <b-form-input
                        id="name"
                        v-model="selectedPriceGroup.name"
                        autofocus
                        :state="getValidationState(validationContext)"
                        :readonly="selectedPriceGroup.is_admin==true"
                        trim
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <b-list-group>
                    <b-list-group-item
                      v-for="(item,index) in discountRateData"
                      :key="index"
                      class="d-flex justify-content-between align-items-center"
                    >

                      <span>{{ item.category_name }}</span>
                      <div>
                        <b-input-group
                          prepend="Liste Fiyatından %"
                          append="İskonto"
                        >
                          <b-form-input v-model="item.discount_rate" />
                        </b-input-group>
                      </div>
                    </b-list-group-item>

                  </b-list-group>

                  <!-- Form Actions -->
                  <div
                    v-if="!selectedPriceGroup.is_admin"
                    class="d-flex mt-2"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-2"
                      type="submit"
                    >
                      {{ $t('Kaydet') }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="button"
                      variant="outline-secondary"
                    >
                      {{ $t('Vazgeç') }}
                    </b-button>
                  </div>

                </b-form>
              </validation-observer>
              <hr>

            </b-col>
          </b-row>

        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import {
  BCard, BRow, BCol, VBModal, BListGroup, BListGroupItem, BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BDropdown, BDropdownItem, BCardTitle, BInputGroup,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import priceGroupStoreModule from './priceGroupStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BListGroup,
    BButton,
    BInputGroup,
    BListGroupItem,
    BFormInvalidFeedback,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BCardTitle,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      showMarkAsReturnedModal: false,
      returnCompletionDate: '',
      showEditSidebar: false,
    }
  },
  methods: {
    selectDataForEdit(item) {
      this.resetPriceGroupData()
      this.selectedPriceGroup = JSON.parse(JSON.stringify(item))
      this.fetchDiscountRates(this.selectedPriceGroup.id)
    },
    // eslint-disable-next-line no-unused-vars
    saveData() {
      this.selectedPriceGroup.discounts = this.discountRateData
      if (this.selectedPriceGroup.id !== 0) {
        store.dispatch('app-price-group/updatePriceGroup', this.selectedPriceGroup)
          .then(response => {
            this.priceGroupsData.forEach((val, index) => {
              if (val.id === this.selectedPriceGroup.id) {
                this.priceGroupsData[index] = response.data.data
              }
            })
            this.showEditSidebar = false
            this.showSuccessMessage()
          })
          .catch(() => {
            this.showErrorMessage()
          })
      } else {
        store.dispatch('app-price-group/addPriceGroup', this.selectedPriceGroup)
          .then(response => {
            this.resetPriceGroupData()
            this.priceGroupsData.push(response.data.data)
            this.showSuccessMessage()
          })
          .catch(() => {
            this.showErrorMessage()
          })
      }
    },
    // eslint-disable-next-line no-unused-vars
    deleteData(index, id) {
      this.$swal({
        title: this.$t('Emin Misiniz ?'),
        text: this.$t('BuRolü Silmek İstediğinizden Emin Misiniz ? (Bu işlem geri alınamaz.)'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Silme İşlemini Onayla'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('app-price-group/deletePriceGroup', { id })
            .then(() => {
              this.priceGroupsData.splice(index, 1)
              this.showSuccessMessage()
            })
            .catch(() => {
              this.showErrorMessage()
            })
        }
      })
    },

    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-price-group'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, priceGroupStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
    const priceGroupsData = ref([])
    const selectedPriceGroup = ref(null)
    const discountRateDataRaw = ref([])
    const discountRateData = ref([])
    const blankPriceGroupData = {
      id: 0,
      name: '',
      is_admin: false,
      permissions: '',

    }
    const fetchDiscountRates = priceGroupId => {
      store.dispatch('app-price-group/fetchPriceGroupDiscountRates', { price_group_id: priceGroupId })
        .then(response => {
          const { data } = response.data

          discountRateDataRaw.value = data
          discountRateData.value = JSON.parse(JSON.stringify(discountRateDataRaw.value))
        })
        .catch(() => {
          alert('Bilgiler getirilirken hata oluştu')
        })
    }

    const fetchpriceGroupsData = () => {
      store.dispatch('app-price-group/fetchPriceGroups', {})
        .then(response => {
          const { data } = response.data
          data.sort((a, b) => (a.category_name > b.category_name ? 1 : -1))
          priceGroupsData.value = data
        })
        .catch(() => {
          alert('Bilgiler getirilirken hata oluştu')
        })
    }
    fetchpriceGroupsData()

    const resetPriceGroupData = () => {
      selectedPriceGroup.value = JSON.parse(JSON.stringify(blankPriceGroupData))

      discountRateData.value = JSON.parse(JSON.stringify(discountRateDataRaw.value))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetPriceGroupData)
    return {
      priceGroupsData,
      blankPriceGroupData,
      fetchpriceGroupsData,
      refFormObserver,
      getValidationState,
      resetPriceGroupData,
      discountRateData,
      fetchDiscountRates,
      selectedPriceGroup,
      resetForm,
      snowOption: {
        theme: 'snow',
      },
    }
  },
}
</script>

<style scoped>
.per-page-selector {
  width: 90px;
}
.list-group-item {
  transition: all 1s
}
#add-new-role-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
